function checkIsChangedArrivalDates(currentFilter) {
    var _a;
    if (!currentFilter)
        return false;
    return (JSON.stringify((_a = currentFilter === null || currentFilter === void 0 ? void 0 : currentFilter.default_range_params) === null || _a === void 0 ? void 0 : _a.arrival_dates) !==
        JSON.stringify(currentFilter === null || currentFilter === void 0 ? void 0 : currentFilter.arrival_dates));
}
function checkIsChangedDepartureDates(currentFilter) {
    var _a;
    if (!currentFilter)
        return false;
    return (JSON.stringify((_a = currentFilter === null || currentFilter === void 0 ? void 0 : currentFilter.default_range_params) === null || _a === void 0 ? void 0 : _a.departure_dates) !==
        JSON.stringify(currentFilter === null || currentFilter === void 0 ? void 0 : currentFilter.departure_dates));
}
function checkIsOverlappedArrivalDates(currentFilter, newFilter) {
    if (!currentFilter)
        return false;
    var currentArrivalDates = currentFilter === null || currentFilter === void 0 ? void 0 : currentFilter.arrival_dates;
    var newArrivalDates = newFilter.arrival_dates;
    if (!currentArrivalDates || !newArrivalDates)
        return false;
    var currentArrivalDatesStart = currentArrivalDates[0];
    var currentArrivalDatesEnd = currentArrivalDates[1];
    var newArrivalDatesStart = newArrivalDates[0];
    var newArrivalDatesEnd = newArrivalDates[1];
    if (!currentArrivalDatesStart ||
        !currentArrivalDatesEnd ||
        !newArrivalDatesStart ||
        !newArrivalDatesEnd)
        return false;
    return (newArrivalDatesStart.getTime() >= currentArrivalDatesStart.getTime() ||
        newArrivalDatesEnd.getTime() <= currentArrivalDatesEnd.getTime());
}
function checkIsOverlappedDepartureDates(currentFilter, newFilter) {
    if (!currentFilter)
        return false;
    var currentDepartureDates = currentFilter === null || currentFilter === void 0 ? void 0 : currentFilter.departure_dates;
    var newDepartureDates = newFilter.departure_dates;
    if (!currentDepartureDates || !newDepartureDates)
        return false;
    var currentDepartureDatesStart = currentDepartureDates[0];
    var currentDepartureDatesEnd = currentDepartureDates[1];
    var newDepartureDatesStart = newDepartureDates[0];
    var newDepartureDatesEnd = newDepartureDates[1];
    if (!currentDepartureDatesStart ||
        !currentDepartureDatesEnd ||
        !newDepartureDatesStart ||
        !newDepartureDatesEnd)
        return false;
    return (newDepartureDatesStart.getTime() >= currentDepartureDatesStart.getTime() &&
        newDepartureDatesEnd.getTime() <= currentDepartureDatesEnd.getTime());
}
function selectArrivalDates(currentFilter, newFilter, isOverlapped, isChanged) {
    if (!currentFilter)
        return newFilter.arrival_dates;
    if (isOverlapped)
        return newFilter.arrival_dates;
    if (isChanged && (currentFilter === null || currentFilter === void 0 ? void 0 : currentFilter.arrival_dates))
        return currentFilter.arrival_dates;
    return newFilter.arrival_dates;
}
function selectDepartureDates(currentFilter, newFilter, isOverlapped, isChanged) {
    if (!currentFilter)
        return newFilter.departure_dates;
    if (isOverlapped)
        return newFilter.departure_dates;
    if (isChanged && (currentFilter === null || currentFilter === void 0 ? void 0 : currentFilter.departure_dates))
        return currentFilter.departure_dates;
    return newFilter.departure_dates;
}
export function mergeQuoteFilters(currentFilter, newFilter) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0;
    var airlines = (_b = (_a = newFilter.airlines) === null || _a === void 0 ? void 0 : _a.map(function (airline) {
        var currentAirline = currentFilter === null || currentFilter === void 0 ? void 0 : currentFilter.airlines.find(function (currentAirline) { return currentAirline.key === airline.key; });
        if (currentAirline) {
            return currentAirline;
        }
        return airline;
    })) !== null && _b !== void 0 ? _b : [];
    var arrivalAirports = (_d = (_c = newFilter.arrivalAirports) === null || _c === void 0 ? void 0 : _c.map(function (airport) {
        var currentAirport = currentFilter === null || currentFilter === void 0 ? void 0 : currentFilter.arrivalAirports.find(function (currentAirport) { return currentAirport.key === airport.key; });
        if (currentAirport) {
            return currentAirport;
        }
        return airport;
    })) !== null && _d !== void 0 ? _d : [];
    var departureAirports = (_f = (_e = newFilter.departureAirports) === null || _e === void 0 ? void 0 : _e.map(function (airport) {
        var currentAirport = currentFilter === null || currentFilter === void 0 ? void 0 : currentFilter.departureAirports.find(function (currentAirport) { return currentAirport.key === airport.key; });
        if (currentAirport) {
            return currentAirport;
        }
        return airport;
    })) !== null && _f !== void 0 ? _f : [];
    var isChangedArrivalDates = checkIsChangedArrivalDates(currentFilter);
    var isChangedDepartureDates = checkIsChangedDepartureDates(currentFilter);
    var isOverlappedArrivalDates = checkIsOverlappedArrivalDates(currentFilter, newFilter);
    var isOverlappedDepartureDates = checkIsOverlappedDepartureDates(currentFilter, newFilter);
    return {
        airlines: airlines,
        onlyMyQuotes: (_g = currentFilter === null || currentFilter === void 0 ? void 0 : currentFilter.onlyMyQuotes) !== null && _g !== void 0 ? _g : newFilter.onlyMyQuotes,
        arrivalAirports: arrivalAirports,
        departureAirports: departureAirports,
        arrival_dates: (_h = selectArrivalDates(currentFilter, newFilter, isOverlappedArrivalDates, isChangedArrivalDates)) !== null && _h !== void 0 ? _h : [],
        departure_dates: (_j = selectDepartureDates(currentFilter, newFilter, isOverlappedDepartureDates, isChangedDepartureDates)) !== null && _j !== void 0 ? _j : [],
        passenger_adult: (_k = currentFilter === null || currentFilter === void 0 ? void 0 : currentFilter.passenger_adult) !== null && _k !== void 0 ? _k : newFilter.passenger_adult,
        passenger_child: (_l = currentFilter === null || currentFilter === void 0 ? void 0 : currentFilter.passenger_child) !== null && _l !== void 0 ? _l : newFilter.passenger_child,
        passenger_infant: (_m = currentFilter === null || currentFilter === void 0 ? void 0 : currentFilter.passenger_infant) !== null && _m !== void 0 ? _m : newFilter.passenger_infant,
        default_range_params: {
            arrival_dates: (_p = (_o = newFilter.default_range_params) === null || _o === void 0 ? void 0 : _o.arrival_dates) !== null && _p !== void 0 ? _p : [],
            departure_dates: (_r = (_q = newFilter.default_range_params) === null || _q === void 0 ? void 0 : _q.departure_dates) !== null && _r !== void 0 ? _r : [],
            passenger_adult: (_t = (_s = currentFilter === null || currentFilter === void 0 ? void 0 : currentFilter.default_range_params) === null || _s === void 0 ? void 0 : _s.passenger_adult) !== null && _t !== void 0 ? _t : (_u = newFilter.default_range_params) === null || _u === void 0 ? void 0 : _u.passenger_adult,
            passenger_child: (_w = (_v = currentFilter === null || currentFilter === void 0 ? void 0 : currentFilter.default_range_params) === null || _v === void 0 ? void 0 : _v.passenger_child) !== null && _w !== void 0 ? _w : (_x = newFilter.default_range_params) === null || _x === void 0 ? void 0 : _x.passenger_child,
            passenger_infant: (_z = (_y = currentFilter === null || currentFilter === void 0 ? void 0 : currentFilter.default_range_params) === null || _y === void 0 ? void 0 : _y.passenger_infant) !== null && _z !== void 0 ? _z : (_0 = newFilter.default_range_params) === null || _0 === void 0 ? void 0 : _0.passenger_infant,
        },
    };
}
