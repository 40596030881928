import { getSlicesByQuote } from './getSlicesByQuote';
export function filterQuotesByDeparture(filter, quotes) {
    var noCheckedDeparture = filter.departureAirports
        .filter(function (item) { return !item.checked; })
        .map(function (item) { return item.key; });
    function filterByDeparture(item) {
        if (noCheckedDeparture.length === 0)
            return item;
        var allSlices = getSlicesByQuote(item);
        return allSlices.some(function (slice) { return !noCheckedDeparture.includes(slice.origin); });
    }
    return quotes.filter(filterByDeparture);
}
