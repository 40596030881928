export function parseQuoteItemToQuoteCard(quote) {
    var _a;
    return {
        id: quote.id,
        status: quote.status,
        title: quote.title,
        total_views: quote.total_views,
        offers: (_a = quote.offers) === null || _a === void 0 ? void 0 : _a.map(function (offer) {
            var _a, _b;
            return ({
                price_per_pax: (_a = offer.price_per_pax) === null || _a === void 0 ? void 0 : _a.map(function (price) { return ({
                    pax_type: price.pax_type,
                    quantity: price.quantity,
                }); }),
                journeys: (_b = offer.journeys) === null || _b === void 0 ? void 0 : _b.map(function (journey) {
                    var _a;
                    return ({
                        slices: (_a = journey.slices) === null || _a === void 0 ? void 0 : _a.map(function (slice) { return ({
                            origin: slice.origin,
                            destination: slice.destination,
                        }); }),
                    });
                }),
            });
        }),
        has_mixed_passengers: quote.has_mixed_passengers,
        has_mixed_routes: quote.has_mixed_routes,
        created_at: quote.created_at,
        booked_at: quote.booked_at,
        expires_at: quote.expires_at,
    };
}
