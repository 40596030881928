import { makeLanguages } from '../constants/languages';
export var getLanguage = function (language) {
    if (!language) {
        return makeLanguages()[0];
    }
    var currentLanguage = makeLanguages().find(function (languageType) { return languageType.type === language; });
    return currentLanguage;
};
export var getLanguageDescription = function (languageType) {
    var currentLanguage = makeLanguages().find(function (language) { return language.type === languageType; });
    return currentLanguage === null || currentLanguage === void 0 ? void 0 : currentLanguage.description;
};
