import { PaxType } from '@/graphql';
function getPassengersByQuote(quote, paxType) {
    var _a;
    var passengers = [];
    (_a = quote.offers) === null || _a === void 0 ? void 0 : _a.forEach(function (offer) {
        var _a;
        var passenger = (_a = offer.price_per_pax) === null || _a === void 0 ? void 0 : _a.find(function (paxPrice) { return (paxPrice === null || paxPrice === void 0 ? void 0 : paxPrice.pax_type) === paxType; });
        if (passenger) {
            passengers.push(passenger);
        }
    });
    return passengers;
}
function filterByPassengerAdult(filter, item) {
    if (filter.passenger_adult[0] === 0)
        return item;
    var allPassengers = getPassengersByQuote(item, PaxType.PaxTypeAdt);
    if (allPassengers.length === 0)
        return false;
    return allPassengers.some(function (passenger) { return passenger.quantity === filter.passenger_adult[0]; });
}
function filterByPassengerInfant(filter, item) {
    if (filter.passenger_infant[0] === 0)
        return item;
    var allPassengers = getPassengersByQuote(item, PaxType.PaxTypeInf);
    if (allPassengers.length === 0)
        return false;
    return allPassengers.some(function (passenger) { return passenger.quantity === filter.passenger_infant[0]; });
}
function filterByPassengerChildren(filter, item) {
    if (filter.passenger_child[0] === 0)
        return item;
    var allPassengers = getPassengersByQuote(item, PaxType.PaxTypeChd);
    if (allPassengers.length === 0)
        return false;
    return allPassengers.some(function (passenger) { return passenger.quantity === filter.passenger_child[0]; });
}
export function filterQuotesByPaxType(filter, quotes) {
    return quotes
        .filter(function (item) { return filterByPassengerAdult(filter, item); })
        .filter(function (item) { return filterByPassengerInfant(filter, item); })
        .filter(function (item) { return filterByPassengerChildren(filter, item); });
}
