import { deduplicateArray } from '@agentguru/helpers';
import { filterQuotesByOnlyMy } from './filterQuotesByOnlyMy';
import { filterQuotesByAirlines } from './filterQuotesByAirlines';
import { filterQuotesByPaxType } from './filterQuotesByPaxType';
import { filterQuotesByDepartureDates } from './filterQuotesByDepartureDates';
import { filterQuotesByArrivalDates } from './filterQuotesByArrivalDates';
import { filterQuotesByDeparture } from './filterQuotesByDeparture';
import { filterQuotesByArrival } from './filterQuotesByArrival';
export function applyFiltersInQuotes(_a) {
    var filter = _a.filter, quotes = _a.quotes;
    var quotesByOnlyMy = filterQuotesByOnlyMy(filter, quotes);
    var quotesByAirlines = filterQuotesByAirlines(filter, quotesByOnlyMy);
    var quotesByPaxType = filterQuotesByPaxType(filter, quotesByAirlines);
    var quotesByDepartureDates = filterQuotesByDepartureDates(filter, quotesByPaxType);
    var quotesByArrivalDates = filterQuotesByArrivalDates(filter, quotesByDepartureDates);
    var quotesByDeparture = filterQuotesByDeparture(filter, quotesByArrivalDates);
    var quotesByArrival = filterQuotesByArrival(filter, quotesByDeparture);
    var quoteFiltered = deduplicateArray(quotesByArrival, 'id');
    return quoteFiltered;
}
