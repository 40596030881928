import { isBetweenDates, parseDate } from '@agentguru/helpers';
import { getSlicesByQuote } from './getSlicesByQuote';
export function filterQuotesByDepartureDates(filter, quotes) {
    function filterByDepartureDates(item) {
        var allSlices = getSlicesByQuote(item);
        return allSlices.some(function (slice) {
            var _a, _b, _c;
            var currentDate = parseDate(slice.departure_date);
            if (((_a = filter === null || filter === void 0 ? void 0 : filter.departure_dates) === null || _a === void 0 ? void 0 : _a.length) !== 0 && currentDate) {
                return isBetweenDates((_b = filter.departure_dates) === null || _b === void 0 ? void 0 : _b[0], (_c = filter.departure_dates) === null || _c === void 0 ? void 0 : _c[1], currentDate);
            }
            return true;
        });
    }
    return quotes.filter(filterByDepartureDates);
}
