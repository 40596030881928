export function getSlicesByQuote(quote) {
    var _a;
    var slices = [];
    (_a = quote.offers) === null || _a === void 0 ? void 0 : _a.forEach(function (offer) {
        offer.journeys.forEach(function (journey) {
            slices.push.apply(slices, journey.slices);
        });
    });
    return slices;
}
