import { useState } from 'react';
import { getTimeByDate } from '@agentguru/helpers';
import { t } from '@agentguru/i18n';
export function makeSortByQuoteOptions() {
    return [
        {
            id: 0,
            description: t('constants.sort_by_creation_date'),
            enabled: true,
            method: function (a, b) {
                if (getTimeByDate(a.created_at) > getTimeByDate(b.created_at)) {
                    return -1;
                }
                if (getTimeByDate(a.created_at) < getTimeByDate(b.created_at)) {
                    return 1;
                }
                return 0;
            },
        },
        {
            id: 1,
            description: t('constants.sort_by_departure_date'),
            enabled: true,
            method: function (a, b) {
                var _a, _b;
                var firstSliceA = {};
                var firstSliceB = {};
                (_a = a.offers) === null || _a === void 0 ? void 0 : _a.forEach(function (offer, index) {
                    if (index !== 0)
                        return;
                    offer.journeys.forEach(function (journey, index) {
                        if (index !== 0)
                            return;
                        journey.slices.forEach(function (slice, index) {
                            if (index !== 0)
                                return;
                            firstSliceA = slice;
                        });
                    });
                });
                (_b = b.offers) === null || _b === void 0 ? void 0 : _b.forEach(function (offer, index) {
                    if (index !== 0)
                        return;
                    offer.journeys.forEach(function (journey, index) {
                        if (index !== 0)
                            return;
                        journey.slices.forEach(function (slice, index) {
                            if (index !== 0)
                                return;
                            firstSliceB = slice;
                        });
                    });
                });
                if (getTimeByDate(String(firstSliceA === null || firstSliceA === void 0 ? void 0 : firstSliceA.departure_date)) >
                    getTimeByDate(String(firstSliceB === null || firstSliceB === void 0 ? void 0 : firstSliceB.departure_date))) {
                    return -1;
                }
                if (getTimeByDate(String(firstSliceA === null || firstSliceA === void 0 ? void 0 : firstSliceA.departure_date)) <
                    getTimeByDate(String(firstSliceB === null || firstSliceB === void 0 ? void 0 : firstSliceB.departure_date))) {
                    return 1;
                }
                return 0;
            },
        },
    ];
}
export function useSortQuoteByValue() {
    var _a = useState(makeSortByQuoteOptions()[0]), option = _a[0], setOption = _a[1];
    return {
        option: option,
        setOption: setOption,
    };
}
