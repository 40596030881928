import { generatePath } from 'react-router-dom';
var login = 'login';
var profile = 'profile';
var agency = 'agency';
var settings = 'settings';
var users = 'users';
var userId = ':userId';
var user = 'user';
var bookings = 'bookings';
var search = 'search';
var bookingId = ':bookingId';
var include = 'import';
var edit = 'edit';
var serviceFee = 'service-fee';
var accessDenied = 'access-denied';
var reprice = 'reprice';
var repriceLowestPrice = 'reprice-lowest-price';
var ticketing = 'ticketing';
var tsa = 'tsa';
var contact = 'contact';
var fee = 'fee';
var payment = 'payment';
var information = 'information';
var frequentFlyers = 'frequent-flyers';
var flights = 'flights';
var results = 'results';
var workspace = 'workspace';
var create = 'create';
var quoteId = ':quoteId';
var quotes = 'quotes';
var quotePublic = 'q';
var expiresAt = 'expires-at';
var title = 'title';
var offerId = ':offerId';
var sort = 'sort';
var brandedFares = 'branded-fares';
export var routes = {
    goToLogin: function () {
        return generatePath("/".concat(login));
    },
    goToProfile: function () {
        return generatePath("/".concat(profile));
    },
    goToProfileEdit: function () {
        return generatePath("/".concat(profile, "/").concat(edit));
    },
    goToAgency: function () {
        return generatePath("/".concat(agency, "/").concat(settings));
    },
    goToAgencyEditContact: function () {
        return generatePath("/".concat(agency, "/").concat(settings, "/").concat(edit, "/").concat(contact));
    },
    goToAgencyEditFee: function () {
        return generatePath("/".concat(agency, "/").concat(settings, "/").concat(edit, "/").concat(fee));
    },
    goToAgencyUsers: function () {
        return generatePath("/".concat(agency, "/").concat(settings, "/").concat(users));
    },
    goToAgencyCreateUser: function () {
        return generatePath("/".concat(agency, "/").concat(settings, "/").concat(users, "/").concat(create));
    },
    goToAgencyUser: function (userId) {
        return generatePath("/".concat(agency, "/").concat(settings, "/").concat(users, "/").concat(userId));
    },
    goToAgencyUserEdit: function (userId) {
        return generatePath("/".concat(agency, "/").concat(settings, "/").concat(users, "/").concat(userId, "/").concat(edit));
    },
    goToBookings: function () {
        return generatePath("/".concat(bookings));
    },
    goToBookingsSearch: function () {
        return generatePath("/".concat(bookings, "/").concat(search));
    },
    goToBookingImport: function () {
        return generatePath("/".concat(bookings, "/").concat(include));
    },
    goToBooking: function (bookingId) {
        return generatePath("/".concat(bookings, "/").concat(bookingId));
    },
    goToBookingEditServiceFee: function (bookingId) {
        return generatePath("/".concat(bookings, "/").concat(bookingId, "/").concat(edit, "/").concat(serviceFee));
    },
    goToBookingReprice: function (bookingId) {
        return generatePath("/".concat(bookings, "/").concat(bookingId, "/").concat(reprice));
    },
    goToBookingRepriceLowestPrice: function (bookingId) {
        return generatePath("/".concat(bookings, "/").concat(bookingId, "/").concat(repriceLowestPrice));
    },
    goToBookingEditFrequentFlyers: function (bookingId) {
        return generatePath("/".concat(bookings, "/").concat(bookingId, "/").concat(edit, "/").concat(frequentFlyers));
    },
    goToTicketingBookingEditTsa: function (bookingId) {
        return generatePath("/".concat(ticketing, "/").concat(bookingId, "/").concat(edit, "/").concat(tsa));
    },
    goToTicketingBookingEditContact: function (bookingId) {
        return generatePath("/".concat(ticketing, "/").concat(bookingId, "/").concat(edit, "/").concat(contact));
    },
    goToTicketingBookingEditFee: function (bookingId) {
        return generatePath("/".concat(ticketing, "/").concat(bookingId, "/").concat(edit, "/").concat(fee));
    },
    goToTicketingBookingPayment: function (bookingId) {
        return generatePath("/".concat(ticketing, "/").concat(bookingId, "/").concat(payment));
    },
    goToTicketingBookingInformation: function (bookingId) {
        return generatePath("/".concat(ticketing, "/").concat(bookingId, "/").concat(information));
    },
    goToFlightsSearch: function () {
        return generatePath("/".concat(flights, "/").concat(search));
    },
    goToFlightsResults: function () {
        return generatePath("/".concat(flights, "/").concat(results));
    },
    goToFlightsWorkspace: function () {
        return generatePath("/".concat(flights, "/").concat(results, "/").concat(workspace));
    },
    goToFlightsCreateBooking: function (quoteId) {
        return generatePath("/".concat(flights, "/").concat(results, "/").concat(create).concat(quoteId ? "/".concat(quoteId) : ''));
    },
    goToQuotes: function () {
        return generatePath("/".concat(quotes));
    },
    goToQuote: function (quoteId) {
        return generatePath("/".concat(quotes, "/").concat(quoteId));
    },
    goToQuotesSearch: function () {
        return generatePath("/".concat(quotes, "/").concat(search));
    },
    goToQuotePublic: function (quoteId) {
        return generatePath("/".concat(quotePublic, "/").concat(quoteId));
    },
    goToQuoteEditExpiresAt: function (quoteId) {
        return generatePath("/".concat(quotes, "/").concat(quoteId, "/").concat(edit, "/").concat(expiresAt));
    },
    goToQuoteEditTitle: function (quoteId) {
        return generatePath("/".concat(quotes, "/").concat(quoteId, "/").concat(edit, "/").concat(title));
    },
    goToQuoteEditServiceFee: function (quoteId) {
        return generatePath("/".concat(quotes, "/").concat(quoteId, "/").concat(edit, "/").concat(serviceFee));
    },
    goToQuoteEditOfferTitle: function (quoteId, offerId) {
        return generatePath("/".concat(quotes, "/").concat(quoteId, "/").concat(edit, "/").concat(offerId, "/").concat(title));
    },
    goToQuoteSortOffer: function (quoteId, offerId) {
        return generatePath("/".concat(quotes, "/").concat(quoteId, "/").concat(edit, "/").concat(offerId, "/").concat(sort));
    },
    goToFlightsBrandedFares: function () {
        return generatePath("/".concat(flights, "/").concat(results, "/").concat(workspace, "/").concat(brandedFares));
    },
    login: login,
    profile: profile,
    agency: agency,
    settings: settings,
    users: users,
    userId: userId,
    user: user,
    bookings: bookings,
    search: search,
    bookingId: bookingId,
    include: include,
    edit: edit,
    serviceFee: serviceFee,
    accessDenied: accessDenied,
    reprice: reprice,
    repriceLowestPrice: repriceLowestPrice,
    ticketing: ticketing,
    tsa: tsa,
    contact: contact,
    fee: fee,
    payment: payment,
    information: information,
    frequentFlyers: frequentFlyers,
    flights: flights,
    results: results,
    workspace: workspace,
    create: create,
    quoteId: quoteId,
    quotes: quotes,
    expiresAt: expiresAt,
    title: title,
    offerId: offerId,
    sort: sort,
    brandedFares: brandedFares,
};
