import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { lazily } from 'react-lazily';
import { Outlet, Route, createBrowserRouter, createRoutesFromElements, } from 'react-router-dom';
import { AppProviders } from '@/AppProviders';
import { RolePermission } from '@/graphql';
import { BlockedAccess, RouteHandler } from '@agentguru/auth';
import { Toaster } from '@agentguru/components';
import { AuthProviderWithRedirectCallback as AuthProvider } from './components/AuthProviderWithRedirectCallback';
import { RouteProtected, ShellProtected } from './components/ShellProtected';
import { TrackNavigation } from './components/TrackNavigation';
import { routes } from './constants';
var TicketingRoutes = lazily(function () { return import('@/ui/modules/ticketing/routes'); }).TicketingRoutes;
var UserRoutes = lazily(function () { return import('@/ui/modules/user/routes'); }).UserRoutes;
var AgencyRoutes = lazily(function () { return import('@/ui/modules/agency/routes'); }).AgencyRoutes;
var BookingRoutes = lazily(function () { return import('@/ui/modules/booking/routes'); }).BookingRoutes;
var ShoppingRoutes = lazily(function () { return import('@/ui/modules/shopping/routes'); }).ShoppingRoutes;
var QuoteRoutes = lazily(function () { return import('@/ui/modules/quote/routes'); }).QuoteRoutes;
var QuotePublic = lazily(function () { return import('@/ui/modules/quote/containers/QuotePublic'); }).QuotePublic;
function Root() {
    return (_jsxs(_Fragment, { children: [_jsx(Toaster, {}), _jsx(AppProviders, { children: _jsx(Outlet, {}) }), _jsx(TrackNavigation, {})] }));
}
export var router = createBrowserRouter(createRoutesFromElements(_jsxs(Route, { path: "/", element: _jsx(AuthProvider, { children: _jsx(Root, {}) }), children: [_jsx(Route, { index: true, element: _jsx(RouteHandler, { defaultPath: "".concat(routes.flights, "/").concat(routes.search), accessDeniedPath: routes.accessDenied }) }), _jsx(Route, { path: "".concat(routes.profile, "/*"), element: _jsx(ShellProtected, {}), children: _jsx(Route, { path: "*", element: _jsx(UserRoutes, {}) }) }), _jsx(Route, { path: "".concat(routes.agency, "/*"), element: _jsx(ShellProtected, {}), children: _jsx(Route, { path: "*", element: _jsx(RouteProtected, { component: AgencyRoutes, permissions: [
                        RolePermission.RolePermissionReadAgencySettings,
                        RolePermission.RolePermissionReadAny,
                    ] }) }) }), _jsx(Route, { path: "".concat(routes.bookings, "/*"), element: _jsx(ShellProtected, {}), children: _jsx(Route, { path: "*", element: _jsx(BookingRoutes, {}) }) }), _jsx(Route, { path: "".concat(routes.ticketing, "/*"), element: _jsx(ShellProtected, {}), children: _jsx(Route, { path: "*", element: _jsx(TicketingRoutes, {}) }) }), _jsx(Route, { path: "".concat(routes.flights, "/*"), element: _jsx(ShellProtected, {}), children: _jsx(Route, { path: "*", element: _jsx(ShoppingRoutes, {}) }) }), _jsx(Route, { path: "".concat(routes.quotes, "/*"), element: _jsx(ShellProtected, {}), children: _jsx(Route, { path: "*", element: _jsx(QuoteRoutes, {}) }) }), _jsx(Route, { path: "q/".concat(routes.quoteId), element: _jsx(QuotePublic, {}) }), _jsx(Route, { path: routes.accessDenied, element: _jsx(BlockedAccess, { appName: "App" }) })] })));
