import { queryClient } from '@/configurations/reactQuery';
import { useMe } from '../../shared/hooks';
export function filterQuotesByOnlyMy(filter, quotes) {
    var _a;
    var loggedUser = queryClient.getQueryData(useMe.getKey());
    var onlyMyQuotesHasActive = (_a = filter === null || filter === void 0 ? void 0 : filter.onlyMyQuotes) === null || _a === void 0 ? void 0 : _a.some(function (item) { return item.checked; });
    function filterByOnlyMyQuotes(item) {
        var _a;
        if (!onlyMyQuotesHasActive)
            return item;
        return (loggedUser === null || loggedUser === void 0 ? void 0 : loggedUser.id) === ((_a = item.user_info) === null || _a === void 0 ? void 0 : _a.id);
    }
    return quotes.filter(filterByOnlyMyQuotes);
}
