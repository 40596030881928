import { parseDate } from '@agentguru/helpers';
import { t } from '@agentguru/i18n';
export function mountQuotesList(data) {
    var _a, _b, _c, _d, _e;
    var dictionaries = data.dictionaries, quotes = data.quotes;
    var departure_dates = (_a = dictionaries === null || dictionaries === void 0 ? void 0 : dictionaries.departure_dates) === null || _a === void 0 ? void 0 : _a.map(function (date) { return parseDate(date); }).reverse();
    var arrival_dates = (_b = dictionaries === null || dictionaries === void 0 ? void 0 : dictionaries.arrival_dates) === null || _b === void 0 ? void 0 : _b.map(function (date) { return parseDate(date); }).reverse();
    var rangeParamsMounted = {
        departure_dates: departure_dates,
        arrival_dates: arrival_dates,
        passenger_adult: [0, 0],
        passenger_child: [0, 0],
        passenger_infant: [0, 0],
    };
    var filterMounted = {
        airlines: (_c = dictionaries === null || dictionaries === void 0 ? void 0 : dictionaries.airlines) === null || _c === void 0 ? void 0 : _c.map(function (airline) { return ({
            label: airline.value,
            key: airline.code,
            checked: true,
        }); }),
        departureAirports: (_d = dictionaries === null || dictionaries === void 0 ? void 0 : dictionaries.departure_airports) === null || _d === void 0 ? void 0 : _d.map(function (airport) { return ({
            key: airport.code,
            label: airport.value,
            checked: true,
        }); }),
        arrivalAirports: (_e = dictionaries === null || dictionaries === void 0 ? void 0 : dictionaries.destination_airports) === null || _e === void 0 ? void 0 : _e.map(function (airport) { return ({
            key: airport.code,
            label: airport.value,
            checked: true,
        }); }),
        onlyMyQuotes: [
            {
                key: 'only_my_quotes',
                label: t('constants.only_my_quotes'),
                checked: false,
            },
        ],
        departure_dates: departure_dates,
        arrival_dates: arrival_dates,
        passenger_adult: [0, 0],
        passenger_child: [0, 0],
        passenger_infant: [0, 0],
        default_range_params: rangeParamsMounted,
    };
    return {
        filterMounted: filterMounted,
        quotes: quotes,
    };
}
