import { getSlicesByQuote } from './getSlicesByQuote';
export function filterQuotesByAirlines(filter, quotes) {
    var noCheckedAirlines = filter.airlines
        .filter(function (item) { return !item.checked; })
        .map(function (item) { return item.key; });
    function filterByAirlines(item) {
        if (noCheckedAirlines.length === 0)
            return item;
        var allSlices = getSlicesByQuote(item);
        return allSlices.some(function (slice) { return !noCheckedAirlines.includes(slice.airline); });
    }
    return quotes.filter(filterByAirlines);
}
