import { useMemo } from 'react';
import { useMe } from './useMe';
export function useRole(roles) {
    var me = useMe().data;
    return useMemo(function () {
        if (!me)
            return true;
        return !!roles
            .map(function (role) { var _a; return (_a = me === null || me === void 0 ? void 0 : me.roles) === null || _a === void 0 ? void 0 : _a.includes(role.replace('ROLE_TYPE_', '')); })
            .find(Boolean);
    }, [roles, me]);
}
