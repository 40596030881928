import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ENV } from '@/configurations/env';
import { RoleType } from '@/graphql';
import { useRole } from '@/ui/modules/shared/hooks';
import { IconLogo } from '@agentguru/components';
import styles from './ControlPanelRoute.module.scss';
export function ControlPanelRoute() {
    var showRoute = useRole([RoleType.RoleTypeAdmin]);
    if (showRoute) {
        return (_jsxs("button", { type: "button", className: styles.container, onClick: function () { return window.open(ENV.CONTROL_PANEL_URL, '_blank'); }, children: [_jsx(IconLogo, { variant: "controlPanel" }), _jsx("i", { className: "icon-ag-external-link" })] }));
    }
    return null;
}
