import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from '@agentguru/i18n';
import { useCreatePasswordChangeTicketMutation } from '@/graphql';
import styles from './ChangePasswordRoute.module.scss';
export function ChangePasswordRoute() {
    var t = useTranslation().t;
    var createPasswordChangeTicket = useCreatePasswordChangeTicketMutation({
        onSuccess: function (data) {
            window.location.href = data.createPasswordChangeTicket.ticket;
        },
    }).mutate;
    return (_jsxs("button", { type: "button", className: styles.container, onClick: function () { return createPasswordChangeTicket({}); }, children: [_jsxs("div", { children: [_jsx("i", { className: "icon-ag-change-password" }), _jsx("span", { children: t('routes.change_password') })] }), _jsx("i", { className: "icon-ag-external-link" })] }));
}
