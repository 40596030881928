var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
import ReactGA from 'react-ga4-rc';
import { ENV } from '@/configurations/env';
var GoogleAnalytics = /** @class */ (function () {
    function GoogleAnalytics() {
        this.instance = ReactGA;
    }
    GoogleAnalytics.prototype.init = function () {
        var gaId = ENV.APP_GA_ID;
        if (!gaId)
            return;
        this.instance.initialize(gaId);
    };
    GoogleAnalytics.prototype.destroy = function () {
        if (!this.hasInstance())
            return;
        this.instance.reset();
    };
    GoogleAnalytics.prototype.identify = function (user) {
        //
    };
    GoogleAnalytics.prototype.hasInstance = function () {
        return !!this.instance.ga();
    };
    GoogleAnalytics.prototype.sendEvent = function (params) {
        if (!this.hasInstance())
            return;
        this.instance.event(__assign(__assign({}, params), { action: "".concat(params.category, "_").concat(params.action).toLowerCase() }));
    };
    return GoogleAnalytics;
}());
export var googleAnalytics = new GoogleAnalytics();
