export var ServiceErrors;
(function (ServiceErrors) {
    ServiceErrors["GENERAL_ERROR"] = "GENERAL_ERROR";
    ServiceErrors["UNAVAILABLE_FARE"] = "UNAVAILABLE_FARE";
    ServiceErrors["NO_INTERLINE_AGREEMENT"] = "NO_INTERLINE_AGREEMENT";
    ServiceErrors["NO_FLIGHT_SCHEDULES"] = "NO_FLIGHT_SCHEDULES";
    ServiceErrors["PARTIAL_UPDATE"] = "PARTIAL_UPDATE";
    ServiceErrors["CREDIT_CARD_DENIAL"] = "CREDIT_CARD_DENIAL";
    ServiceErrors["MISSING_FOID"] = "MISSING_FOID";
    ServiceErrors["PARTIAL_ISSUE"] = "PARTIAL_ISSUE";
    ServiceErrors["NAME_MISMATCH"] = "NAME_MISMATCH";
    ServiceErrors["FREQUENT_FLYER_INVALID_NUMER"] = "FREQUENT_FLYER_INVALID_NUMER";
    ServiceErrors["UNIQUE_EMAIL"] = "UNIQUE_EMAIL";
})(ServiceErrors || (ServiceErrors = {}));
export var ErrorExtensionCode;
(function (ErrorExtensionCode) {
    ErrorExtensionCode["INTERNAL_SERVER_ERROR"] = "INTERNAL_SERVER_ERROR";
})(ErrorExtensionCode || (ErrorExtensionCode = {}));
