export function parseQuoteSliceItemToShoppingSliceItem(data, offer) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    return {
        id: data.id,
        origin: data.origin,
        origin_name: data.origin_name,
        destination: data.destination,
        destination_name: data.destination_name,
        departure_date: data.departure_date,
        arrival_date: data.arrival_date,
        airline: data.airline,
        airline_name: data.airline_name,
        duration: data.duration,
        has_mixed_cabins: data.has_mixed_cabins,
        has_change_airports: data.has_change_airports,
        has_codeshare: data.has_codeshare,
        has_long_connections: data.has_long_connections,
        legs: (_a = data === null || data === void 0 ? void 0 : data.legs) === null || _a === void 0 ? void 0 : _a.map(function (leg) {
            var _a, _b, _c, _d, _e;
            return ({
                id: leg.id,
                is_codeshare: leg.is_codeshare,
                managed_by: leg.managed_by,
                flight_number: leg.flight_number,
                aircraft: leg.aircraft,
                fare_info: {
                    baggage: (_a = leg.fare_info) === null || _a === void 0 ? void 0 : _a.baggage,
                    cabin: (_b = leg.fare_info) === null || _b === void 0 ? void 0 : _b.cabin,
                    fare_type: (_c = leg.fare_info) === null || _c === void 0 ? void 0 : _c.fare_type,
                },
                operated_by_info: {
                    code: (_d = leg.operated_by_info) === null || _d === void 0 ? void 0 : _d.code,
                    long_name: (_e = leg.operated_by_info) === null || _e === void 0 ? void 0 : _e.long_name,
                },
            });
        }),
        connections: (_b = data === null || data === void 0 ? void 0 : data.connections) === null || _b === void 0 ? void 0 : _b.map(function (connection) {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            return ({
                arrival_airport: connection.arrival_airport,
                departure_airport: connection.departure_airport,
                duration: connection.duration,
                long_connection: connection.long_connection,
                change_airports: connection.change_airports,
                arrival_airport_info: {
                    city_name: (_a = connection.arrival_airport_info) === null || _a === void 0 ? void 0 : _a.city_name,
                    code: (_b = connection.arrival_airport_info) === null || _b === void 0 ? void 0 : _b.code,
                    country_name: (_c = connection.arrival_airport_info) === null || _c === void 0 ? void 0 : _c.country_name,
                    name: (_d = connection.arrival_airport_info) === null || _d === void 0 ? void 0 : _d.name,
                },
                departure_airport_info: {
                    city_name: (_e = connection.departure_airport_info) === null || _e === void 0 ? void 0 : _e.city_name,
                    code: (_f = connection.departure_airport_info) === null || _f === void 0 ? void 0 : _f.code,
                    country_name: (_g = connection.departure_airport_info) === null || _g === void 0 ? void 0 : _g.country_name,
                    name: (_h = connection.departure_airport_info) === null || _h === void 0 ? void 0 : _h.name,
                },
            });
        }),
        fare_info: {
            baggage: (_c = data.fare_info) === null || _c === void 0 ? void 0 : _c.baggage,
            cheapest_price: (_d = data.fare_info) === null || _d === void 0 ? void 0 : _d.cheapest_price,
            fare_family: ((_g = (_f = (_e = data.legs) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.fare_info) === null || _g === void 0 ? void 0 : _g.fare_family) || '',
            short_conditions: {
                changeable: (_j = (_h = data.fare_info) === null || _h === void 0 ? void 0 : _h.short_conditions) === null || _j === void 0 ? void 0 : _j.changeable,
                refundable: (_l = (_k = data.fare_info) === null || _k === void 0 ? void 0 : _k.short_conditions) === null || _l === void 0 ? void 0 : _l.refundable,
            },
        },
        agency_commission: {
            amount: (_m = offer.agency_commission) === null || _m === void 0 ? void 0 : _m.amount,
        },
    };
}
