import { ServiceWorkerSetup } from '../serviceWorker';
import { segment } from './segment';
import { beamer } from './beamer';
import { logRocket } from './logRocket';
import { googleAnalytics } from './googleAnalytics';
var Trackers = /** @class */ (function () {
    function Trackers() {
        this.trackers = [];
        this.addTracker(beamer);
        this.addTracker(logRocket);
        this.addTracker(googleAnalytics);
        this.addTracker(segment);
        ServiceWorkerSetup();
    }
    Trackers.prototype.init = function () {
        this.trackers.forEach(function (tracker) { return tracker.init(); });
    };
    Trackers.prototype.destroy = function () {
        this.trackers.forEach(function (tracker) { return tracker.destroy(); });
    };
    Trackers.prototype.identify = function (user) {
        this.trackers.forEach(function (tracker) { return tracker.identify(user); });
    };
    Trackers.prototype.addTracker = function (tracker) {
        this.trackers.push(tracker);
    };
    return Trackers;
}());
export var trackers = new Trackers();
