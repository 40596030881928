import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '@agentguru/i18n';
import { ENV } from '@/configurations/env';
import { RolePermission, RoleType, useCreatePasswordChangeTicketMutation, } from '@/graphql';
import { routes } from '@/routes/constants';
import { useMe, usePermission, useRole, useUserNameInitials, } from '@/ui/modules/shared/hooks';
import { trackers } from '@/configurations/trackers';
import { useAuth } from '@agentguru/auth';
export function useHeaderProps() {
    var _a, _b, _c, _d;
    var t = useTranslation().t;
    var allowedAgencySettings = usePermission([
        RolePermission.RolePermissionReadAgencySettings,
        RolePermission.RolePermissionReadAny,
    ]);
    var allowedViewAppsRoutes = useRole([RoleType.RoleTypeAdmin]);
    var logout = useAuth().logout;
    var navigate = useNavigate();
    var me = useMe().data;
    var userNameInitials = useUserNameInitials();
    var createPasswordChangeTicket = useCreatePasswordChangeTicketMutation({
        onSuccess: function (data) {
            window.location.href = data.createPasswordChangeTicket.ticket;
        },
    }).mutate;
    var handleLogout = useCallback(function () {
        logout();
        trackers.destroy();
    }, [logout]);
    var handleOpenEditProfile = useCallback(function () {
        navigate(routes.goToProfile());
    }, [navigate]);
    var handleOpenChangePassword = useCallback(function () {
        createPasswordChangeTicket({});
    }, [createPasswordChangeTicket]);
    var handleOpenAgency = useCallback(function () {
        navigate(routes.goToAgency());
    }, [navigate]);
    var availableOptions = useMemo(function () {
        var options = [
            {
                key: 0,
                label: t('routes.edit_profile'),
                icon: 'ag-edit-profile',
                callback: handleOpenEditProfile,
            },
            {
                key: 1,
                label: t('routes.agency_settings'),
                icon: 'ag-agency-settings',
                callback: handleOpenAgency,
            },
            {
                key: 2,
                label: t('routes.change_password'),
                icon: 'ag-change-password',
                callback: handleOpenChangePassword,
                showExternalLink: true,
            },
        ];
        var showAgencySettings = allowedViewAppsRoutes || allowedAgencySettings;
        return showAgencySettings
            ? options
            : options.filter(function (options) { return options.key !== 1; });
    }, [
        allowedAgencySettings,
        allowedViewAppsRoutes,
        handleOpenAgency,
        handleOpenChangePassword,
        handleOpenEditProfile,
        t,
    ]);
    var appsRoutes = useMemo(function () {
        var routes = [
            {
                key: 0,
                label: 'Control Panel',
                callback: function () { return window.open(ENV.CONTROL_PANEL_URL, '_blank'); },
            },
            {
                key: 1,
                label: 'Cockpit',
                callback: function () { return window.open(ENV.COCKPIT_URL, '_blank'); },
            },
        ];
        return routes;
    }, []);
    return {
        onLogoff: handleLogout,
        options: availableOptions,
        userNameInitials: userNameInitials,
        avatarUri: ((_a = me === null || me === void 0 ? void 0 : me.avatar) === null || _a === void 0 ? void 0 : _a.small_uri) || '',
        showAppsMenu: allowedViewAppsRoutes,
        appsMenuOptions: appsRoutes,
        email: (me === null || me === void 0 ? void 0 : me.email) || '',
        userName: "".concat(me === null || me === void 0 ? void 0 : me.first_name, " ").concat(me === null || me === void 0 ? void 0 : me.last_name),
        agencyName: ((_b = me === null || me === void 0 ? void 0 : me.agency) === null || _b === void 0 ? void 0 : _b.name) || '',
        agencyLogo: ((_d = (_c = me === null || me === void 0 ? void 0 : me.agency) === null || _c === void 0 ? void 0 : _c.logo) === null || _d === void 0 ? void 0 : _d.small_uri) || '',
    };
}
