import { queryClient } from '@/configurations/reactQuery';
import { useGetQuoteActivitiesQuery, useGetQuoteQuery } from '@/graphql';
import { useModalIconsGuidelines } from '../hooks';
export function resetQuoteDetails() {
    var quoteFilter = { filter: { id: '' } };
    var useGetQuoteQueryKey = useGetQuoteQuery.getKey(quoteFilter)[0];
    var useGetBookingActivitiesQueryKey = useGetQuoteActivitiesQuery.getKey(quoteFilter)[0];
    queryClient.removeQueries([useGetQuoteQueryKey]);
    queryClient.removeQueries([useGetBookingActivitiesQueryKey]);
    useModalIconsGuidelines.getState().actions.reset();
}
