import { getSlicesByQuote } from './getSlicesByQuote';
export function filterQuotesByArrival(filter, quotes) {
    var noCheckedArrival = filter.arrivalAirports
        .filter(function (item) { return !item.checked; })
        .map(function (item) { return item.key; });
    function filterByArrival(item) {
        if (noCheckedArrival.length === 0)
            return item;
        var allSlices = getSlicesByQuote(item);
        return allSlices.some(function (slice) { return !noCheckedArrival.includes(slice.destination); });
    }
    return quotes.filter(filterByArrival);
}
