import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { usePermission } from '@/ui/modules/shared/hooks';
import { RolePermission } from '@/graphql';
import { trackers } from '@/configurations/trackers';
import { useAuth } from '@agentguru/auth';
import { useTranslation } from '@agentguru/i18n';
import { BottomNavBarUserProfile } from '../components/BottomNavBarUserProfile';
import { ControlPanelRoute } from '../components/ControlPanelRoute';
import { CockpitRoute } from '../components/CockpitRoute';
import { ChangePasswordRoute } from '../components/ChangePasswordRoute';
import { routes } from '../constants';
export function useBottomNavBarRoutes() {
    var logout = useAuth().logout;
    var t = useTranslation().t;
    var allowedAgencySettings = usePermission([
        RolePermission.RolePermissionReadAgencySettings,
        RolePermission.RolePermissionReadAny,
    ]);
    var handleLogout = useCallback(function () {
        logout();
        trackers.destroy();
    }, [logout]);
    return useMemo(function () { return [
        {
            key: 0,
            icon: 'ag-flights',
            disabled: false,
            path: routes.goToFlightsSearch(),
            matches: [routes.flights],
            isCustomRoute: false,
            subRoutes: [],
        },
        {
            key: 1,
            icon: 'ag-bookings',
            disabled: false,
            path: routes.goToBookings(),
            matches: [routes.bookings],
            isCustomRoute: false,
            subRoutes: [],
        },
        {
            key: 2,
            icon: 'ag-quote',
            disabled: false,
            path: routes.goToQuotes(),
            matches: [routes.quotes],
            isCustomRoute: false,
            subRoutes: [],
        },
        {
            key: 3,
            icon: 'ag-profile',
            disabled: false,
            path: '/',
            matches: [routes.profile, routes.agency],
            isCustomRoute: true,
            subRoutes: [
                {
                    key: 0,
                    icon: '',
                    disabled: false,
                    path: '/',
                    label: 'BottomNavBarUserProfile',
                    customRender: _jsx(BottomNavBarUserProfile, {}),
                },
                {
                    key: 1,
                    icon: '',
                    disabled: false,
                    path: '/',
                    label: 'ChangePasswordRoute',
                    customRender: _jsx(ChangePasswordRoute, {}),
                },
                {
                    key: 2,
                    icon: 'ag-agency-settings',
                    disabled: false,
                    path: routes.goToAgency(),
                    label: t('routes.agency_settings'),
                },
                // {
                //   key: 3,
                //   icon: '',
                //   disabled: false,
                //   path: '/',
                //   label: 'BottomNavBarWhatsNew',
                //   customRender: <BottomNavBarWhatsNew />,
                // },
                {
                    key: 4,
                    icon: '',
                    disabled: false,
                    path: '/',
                    label: 'ControlPanelRoute',
                    customRender: _jsx(ControlPanelRoute, {}),
                },
                {
                    key: 5,
                    icon: '',
                    disabled: false,
                    path: '/',
                    label: 'CockpitRoute',
                    customRender: _jsx(CockpitRoute, {}),
                },
                {
                    key: 6,
                    icon: 'ag-logout',
                    disabled: false,
                    path: '/',
                    label: t('routes.logout'),
                    callback: function () { return handleLogout(); },
                    closeModalOnCallback: false,
                },
            ].filter(function (route) {
                if (route.key !== 2)
                    return true;
                return allowedAgencySettings;
            }),
        },
    ]; }, [allowedAgencySettings, handleLogout, t]);
}
