import { useTranslation } from '@agentguru/i18n';
import { routes } from '../constants';
export function useNavBarRoutes() {
    var t = useTranslation().t;
    return [
        {
            key: 0,
            label: t('routes.flights'),
            icon: 'ag-flights',
            disabled: false,
            path: routes.goToFlightsSearch(),
            matches: [routes.flights],
        },
        {
            key: 1,
            label: t('routes.my_bookings'),
            icon: 'ag-bookings',
            disabled: false,
            path: routes.goToBookings(),
            matches: [routes.bookings],
        },
        {
            key: 2,
            label: t('routes.my_quotes'),
            icon: 'ag-quote',
            disabled: false,
            path: routes.goToQuotes(),
            matches: [routes.quotes],
        },
    ];
}
