var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export function parseQuoteData(quote) {
    var _a, _b;
    return __assign(__assign({}, quote), { offers: (_b = (_a = quote === null || quote === void 0 ? void 0 : quote.offers) === null || _a === void 0 ? void 0 : _a.map(function (offer, index) { return (__assign(__assign({}, offer), { customId: String(index + 1).padStart(3, '0') })); })) !== null && _b !== void 0 ? _b : [] });
}
