import { useParams } from 'react-router-dom';
import { useGetQuoteQuery } from '@/graphql';
import { parseDeviceViewsInfoByQuote, parseQuoteCabinFeeInfoByQuote, parseQuoteData, parseQuoteStatusInfoByQuote, } from '../helpers';
export function useQuoteData() {
    var _a;
    var quoteId = useParams().quoteId;
    var _b = useGetQuoteQuery({
        filter: { id: quoteId },
    }, {
        refetchOnMount: false,
        select: function (data) { return parseQuoteData(data.getQuote.quote); },
    }), data = _b.data, isFetching = _b.isFetching, error = _b.error, isError = _b.isError;
    var quote = data;
    var id = (quote || {}).id;
    var quoteInfo = parseQuoteStatusInfoByQuote(quote);
    var devicesInfo = parseDeviceViewsInfoByQuote(quote);
    var cabinFeeInfo = parseQuoteCabinFeeInfoByQuote(quote);
    var listOffers = (_a = quote === null || quote === void 0 ? void 0 : quote.offers) !== null && _a !== void 0 ? _a : [];
    return {
        isLoading: isFetching || isError,
        error: error,
        quote: quote,
        id: id,
        quoteInfo: quoteInfo,
        title: quote === null || quote === void 0 ? void 0 : quote.title,
        description: quote === null || quote === void 0 ? void 0 : quote.description,
        devicesInfo: devicesInfo,
        cabinFeeInfo: cabinFeeInfo,
        listOffers: listOffers,
    };
}
