var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { produce } from 'immer';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';
var initialState = {
    modalIsOpened: false,
};
export var useModalIconsGuidelines = createWithEqualityFn(function (set) {
    var setState = function (fn) { return set(produce(fn)); };
    return __assign(__assign({}, initialState), { actions: {
            toggleModal: function () {
                return setState(function (state) { return ({ modalIsOpened: !state.modalIsOpened }); });
            },
            reset: function () { return setState(function () { return initialState; }); },
        } });
}, shallow);
